import { IconButton, Menu, Tooltip, TreeView } from "@fidelix/fx-miranda";
import { useSpinDelay } from "spin-delay";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ReactElement, ReactNode, useState } from "react";
import styled from "styled-components";
import { Trans } from "@lingui/macro";
import { useParsedSearchParams } from "~utils/url";
import {
  useBuildingsSuspenseQuery,
  useLevelGraphicsQuery,
  useLevelsQuery,
} from "../level/level.queries";
import { useDevicesQuery } from "../device/device.queries";
import { Level } from "../level/level.model";
import { Device } from "../device/device.model";
import { useGraphicsQuery } from "./graphics.queries";
import { MenuItems } from "./graphics.model";
import {
  getDeviceStatusColor,
  getDeviceStatusText,
} from "../device/device.utils";

export function DeviceTree() {
  const { data: levels = [] } = useBuildingsSuspenseQuery();

  return (
    <TreeView>
      {levels.map((level) => {
        return (
          <DeviceItemTree key={level.id} level={level} isBuilding isRoot />
        );
      })}
    </TreeView>
  );
}

function DeviceItemTree({
  level,
  isRoot = false,
  isBuilding = false,
  ...rest
}: {
  level: Level;
  isRoot?: boolean;
  isBuilding?: boolean;
}) {
  const { parsedParams, setSearchParams } = useParsedSearchParams({
    open: { parse: (p) => p.getAll("levelId") },
    graphicsId: { type: "string" },
  });

  const { data: devices = [], isLoading: isDevicesLoading } = useDevicesQuery({
    pageSize: Number.MAX_SAFE_INTEGER,
    page: 1,
    levelId: level.id,
  });

  const { data: levelGraphics = [] } = useLevelGraphicsQuery({
    levelId: level.id,
  });

  const { data: subLevels = [], isLoading: isSubLevelsLoading } =
    useLevelsQuery({
      levelId: level.id,
    });

  const isLoading = useSpinDelay(isDevicesLoading || isSubLevelsLoading);
  const openLevels = parsedParams.open;
  function onLevelOpenChange(isOpen: boolean) {
    const idString = String(level.id);
    setSearchParams((params) => {
      if (isOpen) {
        params.append("levelId", idString);
      } else {
        params.delete("levelId", idString);
      }
      return params;
    });
  }

  if (!isRoot && isLoading) {
    return <TreeView.Loader />;
  }

  return (
    <TreeView.Item
      label={level.name}
      icon={isBuilding ? "building" : undefined}
      onOpenChange={onLevelOpenChange}
      isDefaultOpen={openLevels.includes(String(level.id))}
      renderItem={(content) => (
        <HoverActions levelId={level.id}>{content}</HoverActions>
      )}
      {...rest}
    >
      {levelGraphics.length > 0 &&
        levelGraphics.map((graphic, index: number) => (
          <TreeView.Item
            label={graphic.name}
            key={`${graphic.name}-${graphic.levelId}`}
            isDefaultOpen={+(parsedParams.graphicsId || "") === graphic.id}
          >
            {(graphic.items || []).map((item) => (
              <MenuItem
                item={item}
                entityId={level.id}
                key={`${item.name}-${level.id}-${index}`}
                graphicsId={graphic.id}
                path={[graphic.name, item.name]}
                isLevel={true}
              />
            ))}
          </TreeView.Item>
        ))}
      {subLevels.length > 0 &&
        subLevels.map((subLevel) => (
          <DeviceItemTree
            level={subLevel}
            key={subLevel.id}
            isBuilding
            isRoot
          />
        ))}

      {devices.map((device) => (
        <DeviceTreeItem
          device={device}
          key={device.id}
          graphicsId={device.graphics.find((e) => e)?.id}
        />
      ))}
    </TreeView.Item>
  );
}

function DeviceTreeItem({
  device,
  graphicsId,
}: {
  device: Device;
  graphicsId?: number;
}) {
  const { parsedParams, setSearchParams } = useParsedSearchParams({
    open: { parse: (p) => p.getAll("deviceId") },
    graphicsId: { type: "string" },
  });
  const { data: graphics = [] } = useGraphicsQuery({
    graphicsId: graphicsId ? graphicsId : 0,
    enabled: !!graphicsId,
  });

  const openDevices = parsedParams.open;

  function onDeviceOpenChange(isOpen: boolean) {
    const idString = String(device.id);
    setSearchParams((param) => {
      if (isOpen) {
        param.append("deviceId", idString);
      } else {
        param.delete("deviceId", idString);
      }
      return param;
    });
  }

  return (
    <TreeView.Item
      icon="statusSmall"
      iconColor={getDeviceStatusColor(device.status)}
      iconSize={32}
      label={device.name}
      key={device.id}
      onOpenChange={onDeviceOpenChange}
      isDefaultOpen={openDevices.includes(String(device.id))}
      // We have to manually render this node with the tooltip, otherwise the tooltip doesn't align correctly on the device node
      renderItem={(content) => {
        // We know content is a ReactElement hence casting to ReactElement to avoid type errors
        const { props, type } = content as ReactElement;
        return (
          <DeviceItemWrapper as={type}>
            <Tooltip
              content={getDeviceStatusText(device.status)}
              placement="left"
            >
              <TooltipTrigger>{...props.children}</TooltipTrigger>
            </Tooltip>
          </DeviceItemWrapper>
        );
      }}
    >
      {graphics.length > 0 &&
        graphics.map((graphic, index: number) => (
          <TreeView.Item
            label={graphic.name}
            key={`${graphic.name}-${graphic.deviceId}`}
            isDefaultOpen={+(parsedParams.graphicsId || "") === graphicsId}
          >
            {(graphic.items || []).map((item) => (
              <MenuItem
                item={item}
                graphicsId={graphic.id}
                entityId={device.id}
                isLevel={false}
                key={`${item.name}-${device.id}-${index}`}
                path={[graphic.name, item.name]}
              />
            ))}
          </TreeView.Item>
        ))}
    </TreeView.Item>
  );
}

function MenuItem({
  item,
  entityId,
  graphicsId,
  path,
  isLevel,
}: {
  graphicsId: number;
  item: MenuItems;
  entityId: number;
  path: string[];
  isLevel: boolean;
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onMenuLevelSelect = (
    entityId: number,
    graphicsId: number,
    graphicPath: string,
  ) => {
    searchParams.set("path", JSON.stringify(path));
    searchParams.set("graphic", graphicPath);
    searchParams.set("graphicsId", String(graphicsId));

    navigate({
      pathname: `${isLevel ? "levels" : "devices"}/${entityId}/menus`,
      search: createSearchParams(searchParams).toString(),
    });
  };

  const menuPath = searchParams.get("path") || "";

  const nestedMenuItems =
    item.items.length > 0 &&
    item.items.map((m, index: number) => (
      <MenuItem
        item={m}
        graphicsId={graphicsId}
        entityId={entityId}
        key={`${m.name}-${entityId}-${index}`}
        path={[...path, m.name]}
        isLevel={isLevel}
      />
    ));

  return item.ignoreHeader ? (
    nestedMenuItems
  ) : (
    <TreeView.Item
      label={item.name}
      key={`${item.name}-${entityId}`}
      isDefaultOpen={+(searchParams.get("graphicsId") || "") === graphicsId}
      isSelected={JSON.stringify(path) === menuPath}
      onSelect={() => onMenuLevelSelect(entityId, graphicsId, item.path)}
    >
      {nestedMenuItems}
    </TreeView.Item>
  );
}

function HoverActions({
  children,
  levelId,
}: {
  children: ReactNode;
  levelId: number;
}) {
  const [isHovered, setHovered] = useState(false);
  const navigate = useNavigate();

  return (
    <LevelHoverActionsWrapper
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {children}
      {isHovered && (
        <LevelHoverActionsContent>
          <Menu
            onAction={() => navigate(`levels/${levelId}/add-graphics`)}
            trigger={<IconButton icon="plus" size="small" />}
          >
            <Menu.Item id="connect">
              <Trans>Add Graphic</Trans>
            </Menu.Item>
          </Menu>
        </LevelHoverActionsContent>
      )}
    </LevelHoverActionsWrapper>
  );
}

const DeviceItemWrapper = styled.div`
  display: flex;
`;

const TooltipTrigger = styled(Tooltip.Trigger)`
  display: flex;
  align-items: center;
`;

const LevelHoverActionsWrapper = styled.div`
  position: relative;
`;

const LevelHoverActionsContent = styled.div`
  position: absolute;
  right: ${(p) => p.theme.spacing.xxsmall}px;
  top: 50%;
  transform: translateY(-50%);
`;
