export type MenuItems = {
  name: string;
  path: string;
  items: MenuItems[];
  ignoreHeader?: boolean;
};

export class Graphics {
  name: string;
  deviceId: number;
  items: MenuItems[];
  id: number;
  levelId: number;

  constructor(model: Graphics) {
    this.deviceId = model.deviceId;
    this.name = model.name;
    this.items = model.items;
    this.id = model.id;
    this.levelId = model.levelId;
  }
}

export class GraphicsAttachment {
  publicUrl: string;
  name: string;
  constructor(data: GraphicsAttachment) {
    this.publicUrl = data.publicUrl;
    this.name = data.name;
  }
}

export class Company {
  companyId: number;
  defaultGraphicsId: number;
  defaultGraphicsPath: string | null;

  constructor(data: Company) {
    this.companyId = data.companyId;
    this.defaultGraphicsId = data.defaultGraphicsId;
    this.defaultGraphicsPath = data.defaultGraphicsPath;
  }
}
