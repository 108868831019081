import React, { useEffect } from "react";
import { styled } from "styled-components";
import { Outlet, useNavigate } from "react-router-dom";
import { DeviceTree } from "./device-tree";
import { useDefaultGraphicsQuery } from "./default-graphics.queries";

export function GraphicsRoute() {
  const navigate = useNavigate();
  const { data: defaultGraphic } = useDefaultGraphicsQuery();

  useEffect(() => {
    if (defaultGraphic && defaultGraphic?.defaultGraphicsPath) {
      navigate(defaultGraphic?.defaultGraphicsPath);
    }
  }, [defaultGraphic?.companyId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Sidebar>
        <DeviceTree />
      </Sidebar>
      <Outlet />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1;
`;

const Sidebar = styled.aside`
  position: relative;
  min-width: 300px;
  border-right: 1px solid ${(p) => p.theme.colors.lightNeutral};
`;
