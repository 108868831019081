import { Color } from "@fidelix/fx-miranda";
import { t } from "@lingui/macro";
import { DeviceStatus } from "./device.model";

export function getDeviceStatusColor(status?: DeviceStatus): Color {
  switch (status) {
    case DeviceStatus.Connected:
      return "success";
    case DeviceStatus.Disabled:
      return "warning";
    case DeviceStatus.Timeout:
    case DeviceStatus.Idle:
      return "mutedDarkNeutral";
    default:
      return "error";
  }
}

export function getDeviceStatusText(status?: DeviceStatus): string {
  switch (status) {
    case DeviceStatus.Connected:
      return t`Connected`;
    case DeviceStatus.Idle:
      return t`Connecting`;
    case DeviceStatus.Disabled:
      return t`Disabled`;
    default:
      return t`Disconnected`;
  }
}
